export const privateRoutes = {
  HOME: '/',
  MY_LEADS: '/mis-casos',
  LEAD: '/caso',
  NEW_LEAD: '/nuevo-caso',
  PROFILE: '/profile',
  EDIT_PROFILE: '/profile/edit',
  LAWYER: '/abogado',
  PROFILE_EMAIL: '/profile/email',
  PROFILE_PHONE: '/profile/phone',
  PROFILE_PHONE_VERIFICATION: '/profile/phone/verification'
};

export const publicRoutes = {
  REGISTER: '/register',
  LOGIN: '/login',
  MAGIC_LINK: '/magic-link',
  CREATED_LEAD: '/crear',
  DOWNLOAD: '/download',
  VERIFICATION: '/verification',
  VERIFICATION_SMS: '/verification/sms',
  VERIFICATION_EMAIL: '/verification/email',
  EMAIL: '/email'
};
