import useAuth from '@hooks/useAuth';
import CreateLead from '@pages/CreateLead';
import Login from '@pages/Login';
import MagicLink from '@pages/MagicLink';
import Register from '@pages/Register';
import ValidateChangeEmail from '@pages/ValidateChangeEmail';
import ValidateEmail from '@pages/ValidateEmail';
import ValidateSms from '@pages/ValidateSms';
import ValidateUser from '@pages/ValidateUser';
import { privateRoutes, publicRoutes } from '@routes';
import propTypes from 'prop-types';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';

const ValidateAuth = ({ component: Component }) => {
  const { authed } = useAuth();
  return !authed ? Component : <Navigate to={privateRoutes.HOME} />;
};

ValidateAuth.propTypes = {
  component: propTypes.object
};
export default [
  <React.Fragment key={1}>
    <Route path={publicRoutes.CREATED_LEAD} element={<ValidateAuth component={<CreateLead />} />} />
    <Route path={publicRoutes.DOWNLOAD} element={<Navigate to={publicRoutes.LOGIN} replace={true} />}></Route>
    <Route path={publicRoutes.REGISTER} element={<ValidateAuth component={<Register />} />} />
    <Route path={publicRoutes.LOGIN} element={<ValidateAuth component={<Login />} />} />
    <Route path={publicRoutes.MAGIC_LINK}>
      <Route path=":token" element={<MagicLink />} />
    </Route>
    <Route path={publicRoutes.VERIFICATION} element={<ValidateAuth component={<ValidateUser />} />} />
    <Route path={publicRoutes.VERIFICATION_SMS} element={<ValidateAuth component={<ValidateSms />} />} />
    <Route path={publicRoutes.VERIFICATION_EMAIL} element={<ValidateAuth component={<ValidateEmail />} />} />
    <Route path={publicRoutes.EMAIL}>
      <Route path=":type/:token" element={<ValidateChangeEmail />} />
    </Route>
  </React.Fragment>
];
